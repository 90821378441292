import Navbar from './component/Navbar';
import Platform from './component/Platform';
import Features from './component/Features';
import Services from './component/Services';
import OurStory from './component/OurStory';
import Contact from './component/Contact';
import Footer from './component/Footer';
import { Route, Routes } from 'react-router-dom';

function App() {
  return (
    <>
      <Navbar />
      <Routes>
         <Route path='/' element={<Platform />} />
         <Route path='/features' element={<Features />} />
         <Route path='/services' element={<Services />} />
         <Route path='/ourstory' element={<OurStory />} />
         <Route path='/contact' element={<Contact />} />           
      </Routes>
      <Footer />
    </>
  );
}

export default App;
