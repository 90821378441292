import React, { useRef } from 'react';
import emailjs from 'emailjs-com';
import swal from 'sweetalert';


const Contact = () => {
  const form = useRef()

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_4k3ky3c', 'template_03fk42m', form.current, process.env.REACT_APP_EMAIL_KEY)
    
    e.target.reset();

    swal('Your message has been sent successfully!')
  };

  return (
    <section id='contact' className='grid md:grid-cols-2 grid-cols-1 items-center bg-gray-100 md:p-5 py-[40px] md:py-[100px] md:my-10'>
      <div className='hidden md:flex md:flex-col md:text-4xl md:justify-center md:items-center'>
        <h2 className='bg-yellow-200 text-emerald-800 font-semibold px-3 py-1 text-5xl'>CONNECT</h2>
        <h5 className='bg-yellow-200 text-emerald-800 font-semibold px-10 py-1 mt-1 text-3xl'>To Get More</h5>
      </div>
      <form ref={form} onSubmit={sendEmail} className='flex flex-col md:w-[80%]'>
        <input type='text' name='name' placeholder='Full Name' required className='p-2 mb-1 bg-gray-100 border-b-2 border-white focus:border-emerald-600 focus:text-emerald-800 placeholder:text-gray-700 active:bg-gray-100' />
        <input type='email' name='email' placeholder='Email' required className='p-2 mb-1 bg-gray-100 border-b-2 border-white focus:border-emerald-600 focus:text-emerald-800 placeholder:text-gray-700'/>
        <input type='text' name='country' placeholder='Country' required className='p-2 mb-1 bg-gray-100 border-b-2 border-white focus:border-emerald-600 focus:text-emerald-800 placeholder:text-gray-700'/>
        <textarea name='message' rows='7' placeholder='Your Message' required className='p-2 mb-1 bg-gray-100 border-b-2 border-white focus:border-emerald-600 focus:text-emerald-800 placeholder:text-gray-700 text-emerald-800'></textarea>
        <button type='submit' className='bg-emerald-800 text-white text-lg rounded-full hover:bg-yellow-200 hover:text-emerald-800 transition duration-500 px-3 py-2 mt-2 font-semibold'>Send</button>
      </form>
    </section>
  )
}

export default Contact