import React from 'react'
import story01 from '../assets/story/story01.png'
import story02 from '../assets/story/story02.png'



const OurStory = () => {
  return (
    <div className='flex flex-col justify-center items-center mb-8 md:p-5 md:mt-10'>
      <h2 className='text-emerald-800 text-lg font-semibold text-center md:text-2xl mb-8 w-[80%]'>
        <span className='bg-yellow-100 p-2'>
          We work for the future
        </span>
      </h2>
      <p className='text-justify text-gray-800 leading-7 w-[80%] mb-8'>
      In the face of unpredictable markets and a changing climate, farmers have never been asked to do more with less. 
      we believe the future of agriculture depends on providing farmers with the right tools—to cover more ground, 
      make the most of their resources, and apply their skill and experience where it's needed most. That's why we're 
      creating management solutions that help farmers improve profitability.
      </p>
      <img src={story01} alt='green field' className='w-[80%]'/>
      <p className='text-justify text-gray-800 leading-7 w-[80%] mt-8'>
      We are launched Opaco in 2019, inspired by emerging technology in multispectral imagery. Opaco was delivering its services offline to large companies in Iran. Today, we are trying to develop online management solutions that help farmers detect acute issues, track and measure progress, and improve farm performance. Also, we can deliver various services to governments, associations, and businesses with real-time reports on the Opaco platform.
      </p>
      <img src={story02} alt='infography' className='md:w-[50%] w-[90%] mb-4 md:p-8 p-4'/>
    </div>
  )
}

export default OurStory