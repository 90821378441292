import React from 'react'
import layers from '../assets/feature/layers.png'
import feature01 from '../assets/feature/feature01.png'
import feature02 from '../assets/feature/feature02.png'
import feature03 from '../assets/feature/feature03.png'


const Features = () => {
  return (
    <div className='flex flex-col justify-center items-center m-5 md:m-10'>

      <div className='flex md:flex-row flex-col justify-center items-center py-5 xl:w-[80%]'>
        <div className='bg-gray-100 p-3 rounded-lg md:bg-white'>
         <h2 className='mb-4 text-emerald-800 text-lg lg:text-2xl font-semibold'><span className='bg-yellow-100 p-2'>Various Vegetation Indices</span></h2>
         <p className='text-gray-800 text-justify leading-6 mb-2'>Along with NDVI images, we use NDMI, NDRE, SAVI, and NDSI images at various stages to analyze data and provide accurate reports to farmers.</p>
          <ul className='list-disc list-inside'>
            <li className=' text-emerald-800'><span className='text-gray-800'>NDVI: Normalized difference vegetation index</span></li>
            <li className=' text-emerald-800'><span className='text-gray-800'>NDMI: Normalized Difference Moisture Index</span></li>
            <li className=' text-emerald-800'><span className='text-gray-800'>NDRE: Normalized Difference NIR/Rededge</span></li>
            <li className=' text-emerald-800'><span className='text-gray-800'>SAVI: Soil Adjusted Vegetation Index</span></li>
            <li className=' text-emerald-800'><span className='text-gray-800'>NDSI: Normalized Difference Salinity Index</span></li>
          </ul>
        </div>
        <div className='invisible h-0 md:visible md:h-full'>
         <img src={layers} alt='satellite images layers' className='p-4 md:p-4' />
        </div>
      </div>

      <div className='flex md:flex-row flex-col justify-center items-center py-5'>
        <div className='invisible h-0 md:visible md:h-full'>
          <img src={feature01} alt='field health' className='md:rounded-r-full' />
        </div>
        <div className='flex-col bg-gray-100 p-4 rounded-lg md:bg-white md:w-[50%] md:pl-5'>
          <h4 className='mb-4 text-amber-900 text-xl font-semibold'>Field Health</h4>
          <p className='text-gray-800 text-justify leading-6'>We can calculate the amounts of nutrients in vegetation, the water content in the soil, plant temperature, and much more. We help farmers keep track of the field’s health, detect subtle changes and act before they affect their crops.</p>
        </div>
      </div>

      <div className='flex md:flex-row flex-col justify-center items-center py-5'>
        <div div className='flex-col bg-gray-100 p-4 rounded-lg md:bg-white md:w-[50%] md:pr-5'>
          <h4 className='mb-4 text-amber-900 text-xl font-semibold'>Growth Problems</h4>
          <p className='text-gray-800 text-justify leading-6'>Our platform can analyze images, taken in proximity to sowing time, in order to detect growth irregularities while there is still time to resow or to correct the underlying problem. As with the disease model, the extensiveness of the coverage of the field enables a field-wide assessment.</p>
        </div>
        <div className='invisible h-0 md:visible md:h-full'>
          <img src={feature02} alt='growth problems' className='md:rounded-l-full' />
        </div>
      </div>

      <div className='flex md:flex-row flex-col justify-center items-center py-5 mb-5'>
        <div className='invisible h-0 md:visible md:h-full'>
          <img src={feature03} alt='drone imagery' className='md:rounded-r-full' />
        </div>
        <div div className='flex-col bg-gray-100 p-4 rounded-lg md:bg-white md:w-[50%] md:pl-5'>
          <h4 className='mb-4 text-amber-900 text-xl font-semibold'>Drone Imagery</h4>
          <p className='text-gray-800 text-justify leading-6'>Drone Imagery has ultra-high-resolution, whole field imagery is used to build zones for scouting and conversion into machine-ready prescriptions.</p>
        </div>
      </div>



    </div>
  )
}

export default Features