import React, {useState} from 'react'
import {Link, NavLink} from 'react-router-dom'
import {FaBars, FaTimes} from 'react-icons/fa'
import logo from '../assets/logo.png'
import '../index.css'

const Navbar = () => {

  const[click, setClick] = useState(false)
  const handleClick = () => setClick(!click)

  const navLinkStyle = ({ isActive }) => {
    return {
      color: isActive ? '#065f46' : '',
    }
  } 

  return (
    <div className='flex justify-between items-center py-6 text-gray-700 text-sm md:text-lg font-semibold w-full'>
        <img src={logo} alt='logo' className='w-[85px]'/>
        <div className='hidden md:flex'>
          <NavLink exact to='/' end className='hover:text-emerald-700 transition duration-500 px-3' style={navLinkStyle}>Platform</NavLink>
          <NavLink exact to='/features' className='hover:text-emerald-700 transition duration-500 px-3' style={navLinkStyle}>Features</NavLink>
          <NavLink exact to='/services' className='hover:text-emerald-700 transition duration-500 px-3' style={navLinkStyle}>Services</NavLink>
          <NavLink exact to='/ourstory' className='hover:text-emerald-700 transition duration-500 px-3' style={navLinkStyle}>Story</NavLink>
          <NavLink exact to='/contact' className='hover:text-emerald-700 transition duration-500 px-3' style={navLinkStyle}>Contact</NavLink>
        </div>

        <div className='md:hidden header'>
           <ul className={click ? 'nav-menu active' : 'hidden'}>
               <li>
                <Link to='/' className='hover:text-emerald-700 transition duration-500 px-3'>Platform</Link>
               </li>
               <li>
                <Link to='/features' className='hover:text-emerald-700 transition duration-500 px-3'>Features</Link>
               </li>
               <li>
                <Link to='/services' className='hover:text-emerald-700 transition duration-500 px-3'>Services</Link>
               </li>
               <li>
                <Link to='/ourstory' className='hover:text-emerald-700 transition duration-500 px-3'>Story</Link>
               </li>
               <li>
                <Link to='/contact' className='hover:text-emerald-700 transition duration-500 px-3'>Contact</Link>
               </li>
           </ul>
           <div className='hamburger' onClick={handleClick}>
            {click ? (<FaTimes size={20} style={{color: '#065f46'}} />) : (<FaBars size={20} style={{color: '#065f46'}} />)}     
           </div>
        </div>
    </div>
  )
}

export default Navbar