import React from 'react'
import {FaLinkedinIn} from 'react-icons/fa'

const Footer = () => {
  return (
    <footer className='p-2 border-t border-emerald-800 mt-5 w-full bg-white/80'>
      <div className='flex justify-between items-center'>
        <div>
          <small>&copy; 2021 Opaco. All rights reserved.</small>
        </div>
        <div className='text-md bg-blue-900 p-2 rounded-full text-white hover:bg-gray-300 hover:text-blue-900 transition duration-500'>
          <a href='https://www.linkedin.com/company/opaco-ir/' target='_blank'><FaLinkedinIn /></a>
        </div>
      </div>     
    </footer>
  )
}

export default Footer