import React from 'react'
import service01 from '../assets/services/service01.png'
import service02 from '../assets/services/service02.png'
import service03 from '../assets/services/service03.png'
import service04 from '../assets/services/service04.png'

const Services = () => {
  return (
    <div className='flex flex-col justify-center items-center m-5 md:m-10 md:p-4'>
      <div className='mb-5'>
        <h3 className='text-emerald-800 text-lg font-semibold text-center md:text-2xl bg-yellow-100 p-2 mb-1'>
          We can provide strong and proven reports across millions of acres.
       </h3>
        <h3 className='text-emerald-800 text-lg font-semibold text-center md:text-2xl p-2'>
          <span className=' bg-yellow-100 p-2'>
            Satellites and drones enable data acquisition at all levels.
          </span>
        </h3>
      </div>
      
      <div className='grid md:grid-cols-2 grid-col-1 justify-center items-center py-5'>
        <div className='bg-gray-100 p-3 rounded-lg md:bg-white'>
         <h4 className='mb-4 text-gray-900 text-xl font-semibold'>Vegetation and forest</h4>
          <ul className='list-disc list-inside '>
            <li className=' text-emerald-800 text-lg'><span className='text-gray-800'>Vegetation health monitoring</span></li>
            <li className=' text-emerald-800 text-lg'><span className='text-gray-800'>Deforestation monitoring</span></li>
            <li className=' text-emerald-800 text-lg'><span className='text-gray-800'>Forest fire mapping</span></li>
            <li className=' text-emerald-800 text-lg'><span className='text-gray-800'>Pest and disease management</span></li>
            <li className=' text-emerald-800 text-lg'><span className='text-gray-800'>Estimation of yield and crop quality</span></li>
            <li className=' text-emerald-800 text-lg'><span className='text-gray-800'>Investigation of agricultural areas affected by water shortage</span></li>
          </ul>
        </div>
        <div className='invisible h-0 md:visible md:h-full'>
         <img src={service01} alt='vegetation and forest' className='p-4 md:p-4 md:w-[90%]' />
        </div>
      </div>

      <div className='grid md:grid-cols-2 grid-col-1 justify-center items-center py-5'>
        <div className='invisible h-0 md:visible md:h-full'>
         <img src={service02} alt='natural hazards' className='md:p-4 p-4 md:w-[80%]' />
        </div>
        <div className='bg-gray-100 p-3 rounded-lg md:bg-white'>
         <h4 className='mb-4 text-gray-900 text-xl font-semibold'>Natural hazards</h4>
          <ul className='list-disc list-inside md:w-[80%]'>
            <li className=' text-emerald-800 text-lg'><span className='text-gray-800'>Natural hazard monitoring (Earthquakes, tsunamis, volcanoes, severe tides, floods)</span></li>
            <li className=' text-emerald-800 text-lg'><span className='text-gray-800'>Prevention of the spread of fire with continuous monitoring</span></li>
            <li className=' text-emerald-800 text-lg'><span className='text-gray-800'>Determining areas with high damage and prioritizing relief</span></li>
          </ul>
        </div>
      </div>

      <div className='grid md:grid-cols-2 grid-col-1 justify-center items-center py-5'>
        <div className='bg-gray-100 p-3 rounded-lg md:bg-white'>
         <h4 className='mb-4 text-gray-900 text-xl font-semibold'>Land use and urban planning</h4>
          <ul className='list-disc list-inside'>
            <li className=' text-emerald-800 text-lg'><span className='text-gray-800'>Urban sprawl mapping</span></li>
            <li className=' text-emerald-800 text-lg'><span className='text-gray-800'>Urban hazards</span></li>
            <li className=' text-emerald-800 text-lg'><span className='text-gray-800'>Population density assessment</span></li>
            <li className=' text-emerald-800 text-lg'><span className='text-gray-800'>Urban natural resources</span></li>
            <li className=' text-emerald-800 text-lg'><span className='text-gray-800'>Determination of urban area and monitoring of urban growth</span></li>
            <li className=' text-emerald-800 text-lg'><span className='text-gray-800'>Location of shelters</span></li>
            <li className=' text-emerald-800 text-lg'><span className='text-gray-800'>Location of urban green spaces</span></li>
            <li className=' text-emerald-800 text-lg'><span className='text-gray-800'>Location of municipal waste disposal</span></li>
          </ul>
        </div>
        <div className='invisible h-0 md:visible md:h-full'>
          <img src={service03} alt='land use and urban planning' className='md:p-4 p-4 md:w-[80%]' />
        </div>
      </div>

      <div className='grid md:grid-cols-2 grid-col-1 justify-center items-center py-5'>
        <div className='invisible h-0 md:visible md:h-full'>
         <img src={service04} alt='change detection' className='md:p-4 p-4 md:w-[90%]' />
        </div>
        <div className='bg-gray-100 p-3 rounded-lg md:bg-white'>
         <h4 className='mb-4 text-gray-900 text-xl font-semibold'>Change detection</h4>
          <ul className='list-disc list-inside'>
            <li className=' text-emerald-800 text-lg'><span className='text-gray-800'>Forest cover changes monitoring</span></li>
            <li className=' text-emerald-800 text-lg'><span className='text-gray-800'>Urban expansion monitoring</span></li>
            <li className=' text-emerald-800 text-lg'><span className='text-gray-800'>Monitoring the sea level and lakes changes</span></li>
            <li className=' text-emerald-800 text-lg'><span className='text-gray-800'>Rangeland monitoring</span></li>
          </ul>
        </div>
      </div>

    </div>
  )
}

export default Services