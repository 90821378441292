import React from 'react'
import Hand from '../assets/platform/hand.jpg'
import Community from '../assets/platform/community.png'
import Consulting from '../assets/platform/consulting.png'
import Fields from '../assets/platform/fields.png'
import Farming from '../assets/platform/farming.png'
import Fertilizer from '../assets/platform/fertilizer.png'
import Scouting from '../assets/platform/scoutings.png'
import Weather from '../assets/platform/weather.png'


const Platform = () => {
  return (
    <div className='flex flex-col justify-center items-center mb-8 md:p-5 md:mt-5'>
      
      <div className='flex flex-col md:flex-row justify-center items-center w-[75%] md:w-[85%]'>
          <div>
            <h2 className='px-4 text-xl md:text-2xl lg:text-2xl mb-4 text-emerald-800 font-semibold'><span className='p-1 bg-yellow-100'>Free platform</span></h2>
            <h3 className='lg:text-2xl md:text-lg text-sm px-4 mb-4 text-emerald-800 font-semibold'><span className='p-1 bg-yellow-100'>for precision farming</span></h3>
            <p className='lg:text-lg md:text-base text-gray-800 px-4 md:text-justify leading-9 md:w-[90%]'>Monitor your crops remotely, find problem spots in your fields, add notes, apply variable fertilizer rates, create seeding prescriptions, share your experiences with others, and have an online consultant beside yourself.</p>        
          </div>
          <img src={Hand} alt='Platform' className='p-4 md:p-4 md:w-[60%]'/>
      </div>

      <div className='flex flex-col justify-center items-center mb-10 p-5'>
        <h3 className='text-2xl text-gray-900 text-center p-2 mb-3 font-semibold'>Reduce costs, improve yields</h3>
        <p className='text-lg text-gray-800 text-center mb-2'>We are developing an advanced decision support toolkit that leverages prescriptive analytics to increase productivity.</p>
        <p className='text-lg text-gray-800 text-center'>You don't have to be a tech wizard to use Opaco: we keep it clear and intuitive.</p>
      </div>

      <div className='p-20 bg-gray-200'>
        <div className='grid grid-cols-1 md:grid-cols-3 text-center bg-gray-200 mb-8'>
          <div className='flex flex-col justify-center items-center my-2'>
            <img src={Fields} alt='fields' className='w-[100px] mb-4'/>
            <h4 className='mb-4 text-xl font-semibold'>Fields</h4>
            <p className='md:w-[65%] leading-6'>Find your field on the map and to see the information</p>
          </div>
          <div className='flex flex-col justify-center items-center my-2'>
            <img src={Scouting} alt='scouting' className='w-[100px] mb-4'/>
            <h4 className='mb-4 text-xl font-semibold'>Scouting</h4>
            <p className='md:w-[65%] leading-6'>Monitor your plants and add notes for yourself or to share</p>
          </div>
          <div className='flex flex-col justify-center items-center my-2'>
            <img src={Weather} alt='weather' className='w-[100px] mb-4'/>
            <h4 className='mb-4 text-xl font-semibold'>Weather</h4>
            <p className='md:w-[65%] leading-6'>Check weather forecast for field management</p>
          </div>
        </div>
        
        <div className='grid grid-cols-1 md:grid-cols-3 text-center bg-gray-200'>
          <div className='flex flex-col justify-center items-center my-2'>
            <img src={Consulting} alt='consulting' className='w-[100px] mb-4'/>
            <h4 className='mb-4 text-xl font-semibold'>Consulting</h4>
            <p className='md:w-[65%] leading-6'>Farmers can choose an agribusiness consultant online based on their needs and budget</p>
          </div>
          <div className='flex flex-col justify-center items-center my-2'>
            <img src={Fertilizer} alt='fertilizer' className='w-[100px] mb-4'/>
            <h4 className='mb-4 text-xl font-semibold'>Fertilizers</h4>
            <p className='md:w-[65%] leading-6'>Determine variable rates to apply nitrogen, phosphorus, and potassium based on the field need</p>
          </div>
          <div className='flex flex-col justify-center items-center my-2'>
            <img src={Community} alt='community' className='w-[100px] mb-4'/>
            <h4 className='mb-4 text-xl font-semibold'>Community</h4>
            <p className='md:w-[65%] leading-6'>Farmers can share experiences. We classify the information to improve accessibility</p>
          </div>
        </div>
      </div>

      <div className='flex flex-col md:flex-row justify-center items-center m-4'>
        <img src={Farming} alt='farming' className='md:w-[50%]' />
        <div className='flex flex-col md:w-[50%]'>
          <p className='px-5 md:text-justify py-5'>Today's mainstream farming practices are not sustainable Earth's population continues to grow, which puts pressure on food systems. That means more demand and less supply. Farmers struggle with low profitability and have limited access to independent agri-business expertise</p>
          <h3 className='px-5 py-2 font-medium'>Opaco brings farm management solutions to farmers:</h3>
          <ul className='px-5 list-disc list-inside'>
            <li className=' text-emerald-800'><span className='text-gray-800'>Platform-assisted digital agronomy advice</span></li>
            <li className=' text-emerald-800'><span className='text-gray-800'>Improve productivity</span></li>
            <li className=' text-emerald-800'><span className='text-gray-800'>Reduces farm pests</span></li>
            <li className=' text-emerald-800'><span className='text-gray-800'>Increases farm profitability</span></li>
          </ul>
        </div>
      </div>

    </div>
  )
}

export default Platform